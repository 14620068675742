import React from 'react'
import Banner from '../../components/Banner'
import Header from '../../components/Header';
import HomeHeader from '../../components/HomeHeader';
import HomeTopBar from '../../components/HomeTopBar';
import Layout from '../Layout';
import B2BForm from './B2BForm';

const B2BRegistration = () => {

    return (
        <Layout head={"Company Registration"}>         
            <B2BForm/>            
        </Layout>
    )
}

export default B2BRegistration
